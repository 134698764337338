import Portfolio from "./components/Portfolio";
import Particle from "./components/Particle";



function App() {
//   

  return (
    <>
      <Particle/>
      <Portfolio />
    </>
  
        
  );
}

export default App;
